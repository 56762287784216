import React from "react"
import { Link } from "gatsby"

const PageLink = props => {
  if (props.to) {
    return (
      <Link to={props.to}>
        <div className="page-link">
          <span className="page-link-content">{props.title}</span>
        </div>
      </Link>
    )
  } else {
    return (
      <a href={props.href}>
        <div className="page-link">
          <span className="page-link-content">{props.title}</span>
        </div>
      </a>
    )
  }
}

export default PageLink

import React, { Component } from "react"
import uuid from "uuid"
import { Link as Scroll } from "react-scroll"
import { Link } from "gatsby"

// import moment from "moment"
// import { PDFDownloadLink } from "@react-pdf/renderer"
// import { PdfDocument } from "../components/pdf"

import { connect } from "react-redux"
import { removeQuestion, clearAllQuestions, getQuestions } from "../state/app"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageLink from "../components/pagelink"

import "../components/pdf.scss"
import "../pages/practice.scss"

class SavedQuestions extends Component {
  componentDidMount() {
    if (this.props.isLoggedIn) {
      this.props.getQuestions(this.props.user)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
      this.props.getQuestions(this.props.user)
    }
  }

  unsaveQuestion = question => {
    this.props.removeQuestion(question)
  }

  clearAllQuestions = () => {
    this.props.clearAllQuestions(this.props.user)
  }

  render() {
    let { firebaseQuestions } = this.props
    let questionList = firebaseQuestions.map((question, i) => (
      <React.Fragment key={uuid.v4()}>
        <div className="mb-4" key={uuid.v4()}>
          <div className="flex-between">
            <span className="header-4 v-align">{"Question " + (i + 1)} </span>
            <span
              key={uuid.v4()}
              unselectable="on"
              className="unsave ml-8 hoverable v-align"
              onClick={this.unsaveQuestion.bind(this, question)}
            >
              UNSAVE
            </span>
          </div>
          <div className="mb-8">
            <span className="lozenge lozenge-green">{question.type}</span>
          </div>
          <div className="header-3" key={uuid.v4()}>
            {question.question}
          </div>
          <p key={uuid.v4()}>{question.answer}</p>
        </div>
      </React.Fragment>
    ))

    return (
      <Layout location={this.props.location} crumbLabel="Practice">
        <SEO
          title="Saved Questions"
          description="Saved questions that you can return to later. You must create an account and sign in before you can save questions."
        />
        <div className="page page-saved">
          <div className="content practice-header mb-16">
            <h1 className="header-1 v-align">Saved Questions</h1>
            <span className="v-align lozenge">
              {firebaseQuestions.length} SAVED
            </span>
          </div>
          <PageLink to="/practice" title="Practice Questions" />
          {firebaseQuestions.length === 0 && (
            <div className="content">
              {!this.props.isLoggedIn
                ? [
                    <Link className="active-link" to="/sign-in">
                      Login
                    </Link>,
                    " to save questions. ",
                  ]
                : "No questions saved. "}
              Once you save questions, they will appear here.
            </div>
          )}
          {firebaseQuestions.length > 0 && (
            <React.Fragment>
              {firebaseQuestions.length > 1 && (
                <span
                  className="content badge unsave hoverable"
                  onClick={this.clearAllQuestions}
                >
                  Unsave All
                </span>
              )}
              <div className="saved-questions">
              {questionList}
              </div>

              {/* <PDFDownloadLink
                document={<PdfDocument data={questions} />}
                fileName={
                  "hackib-questions-" +
                  moment()
                    .format("MMM-Do-YY-h-mm-ss-a")
                    .toLowerCase() +
                  ".pdf"
                }
                className="content btn download-pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading..." : "Export to PDF"
                }
              </PDFDownloadLink> */}
            </React.Fragment>
          )}
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.app.user,
    isLoggedIn: state.app.isLoggedIn,
    firebaseQuestions: state.app.firebaseQuestions,
    loading: state.app.loading,
  }
}

export default connect(mapStateToProps, {
  removeQuestion,
  clearAllQuestions,
  getQuestions,
})(SavedQuestions)
